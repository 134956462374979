import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { Colors } from 'common-constants/colors'
import { GullAnimation } from 'components/block/GullAnimation/GullAnimation'
import bottleReflectionSvg from 'components/designSystem/svg/bottle-reflection.svg'
import bottleSvg from 'components/designSystem/svg/bottle.svg'
import robot3SplashSvg from 'components/designSystem/svg/robot-splash3.svg'
import { usePreloadImagesAfterWindowLoad } from 'hooks/usePreloadImagesAfterWindowLoad'

import { AgeReflectionSvg } from './AgeReflectionSvg'
import { AnimatedAge } from './AnimatedAge'
import { AnimatedLetter } from './AnimatedLetter'
import { FlamingoReflectionSvg } from './FlamingoReflectionSvg'
import { FlamingoSvg } from './FlamingoSvg'
import { GullReflectionSvg } from './GullReflectionSvg'
import { MailboxReflectionSvg } from './MailboxReflectionSvg'
import { MailboxSvg } from './MailboxSvg'
import { NameLetterReflexSvg } from './NameLetterReflexSvg'
import { PeriscopeReflectionSvg } from './PeriscopeReflectionSvg'
import { PeriscopeSvg } from './PeriscopeSvg'
import { BOX_SIZE } from './WaveBoxes.constants'
import { SurfingGirlAnimation } from '../../block/SurfingGirlAnimation/SurfingGirlAnimation'
import { formaDJRCyrillicTextFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const WaveOnboardingBoxes: FC<{ loginFlow: boolean | undefined }> = ({
  loginFlow,
}) => {
  usePreloadImagesAfterWindowLoad([
    robot3SplashSvg,
    bottleSvg,
    bottleReflectionSvg,
  ])

  return (
    <>
      <Box style={{ top: 10 }}>
        <BoxInner>
          <SurfingGirlAnimation />
        </BoxInner>
      </Box>
      <Box>
        {loginFlow ? (
          <BoxInner>
            <GullAnimation />
            <GullReflection />
          </BoxInner>
        ) : (
          <BoxInner>
            <FlamingoSvg />
            <FlamingoReflection />
          </BoxInner>
        )}
      </Box>
      <Box>
        {!loginFlow && (
          <BoxInner>
            <NameLetter>
              <AnimatedLetter />
            </NameLetter>
            <NameLetterReflection />
          </BoxInner>
        )}
      </Box>
      <Box>
        {!loginFlow && (
          <BoxInner>
            <AgeNumber>
              <AnimatedAge />
            </AgeNumber>
            <AgeReflection />
          </BoxInner>
        )}
      </Box>
      <Box>
        {!loginFlow && (
          <BoxInner>
            <Periscope />
            <PeriscopeReflection />
          </BoxInner>
        )}
      </Box>
      <Box>
        {!loginFlow && (
          <BoxInner>
            <MailboxSvg />
            <MailboxReflection />
          </BoxInner>
        )}
      </Box>
    </>
  )
}

export const VerificationWaveBoxes: FC = () => {
  return (
    <Box>
      <Image src={robot3SplashSvg} />
    </Box>
  )
}

export const PromoCodeWaveBoxes: FC = () => {
  return (
    <Box>
      <Image src={bottleSvg} style={{ transform: 'translate(10px, 0px)' }} />
      <Image style={{ position: 'absolute' }} src={bottleReflectionSvg} />
    </Box>
  )
}

const Image = styled.img`
  width: 100%;
  height: 100%;
`
const BoxInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: ${BOX_SIZE}px;
  height: ${BOX_SIZE}px;
  animation: wave-swing 1s ease-in-out infinite alternate;
  transform-origin: bottom center;
  will-change: transform;

  @keyframes wave-swing {
    0% {
      rotate: -8deg;
    }

    100% {
      rotate: 8deg;
    }
  }
`
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: ${BOX_SIZE}px;
  height: ${BOX_SIZE}px;
  will-change: transform;
`
const FlamingoReflection = styled(FlamingoReflectionSvg)`
  position: absolute;
`
const GullReflection = styled(GullReflectionSvg)`
  position: absolute;
  transform: scaleX(1.2);
`
const NameLetter = styled.div`
  width: 100%;
  position: relative;
  top: 23px;
  color: ${Colors.waveColor};
  text-align: center;
  font-family: ${formaDJRCyrillicTextFontFamily};
  font-size: 74px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const NameLetterReflection = styled(NameLetterReflexSvg)`
  position: absolute;
  top: -10px;
  transform: scaleX(1.2);
`
const AgeNumber = styled.div`
  width: 100%;
  position: relative;
  top: 30px;
  color: ${Colors.waveAgeColor};
  text-align: center;
  font-family: ${formaDJRCyrillicTextFontFamily};
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const AgeReflection = styled(AgeReflectionSvg)`
  position: absolute;
  top: -6px;
  transform: scaleX(1.1);
`
const Periscope = styled(PeriscopeSvg)`
  position: relative;
`
const PeriscopeReflection = styled(PeriscopeReflectionSvg)`
  position: absolute;
`
const MailboxReflection = styled(MailboxReflectionSvg)`
  position: absolute;
`
